import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { saveAs } from "file-saver"
import { convertArrayToCSV } from "convert-array-to-csv";
import { calculateState, calculateUsage } from "./common";
import moment from "moment";
import { Link } from "react-router-dom";
import RichTable from "./RichTable";
import { MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    panel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
    }
}));

export default function GroupGroups({
    organizationId,
    group,
    since,
    until,
    groups,
    onDelete,
    admin
}) {
    const classes = useStyles();


    function onDownload(selection, downloadType) {

    }

    const groupTypeToName = {
        "HEATING_COST_ALLOCATION_BUILDING": "Podział ciepła - budynek",
        "HEATING_COST_ALLOCATION_FLAT": "Podział ciepła - mieszkanie",
        "HEATING_COST_ALLOCATION_COMMON_AREA": "Podział ciepła - część wspólna",
        "HEATING_COST_ALLOCATION_ROOM": "Podział ciepła - pomieszczenie",
    };

    var name = null;

    if (group) {
        switch (group.type) {
            case "HEATING_COST_ALLOCATION_BUILDING":
                name = "Lokale";
                break;
            case "HEATING_COST_ALLOCATION_FLAT":
                name = "Pomieszczenia";
                break;
            case "HEATING_COST_ALLOCATION_COMMON_AREA":
                name = "Pomieszczenia";
                break;
        }
    }

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <RichTable name={name} columns={
                        [
                            {
                                id: 'name',
                                numeric: false,
                                disablePadding: false,
                                label: 'Nazwa',
                                render: (row) => (<Link className={classes.link}
                                    to={"/organizations/" + organizationId + "/groups/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>),
                                visible: true
                            },
                            { id: 'id', numeric: false, disablePadding: false, label: 'ID', visible: true },
                            {
                                id: 'type',
                                numeric: false,
                                disablePadding: false,
                                label: 'Typ',
                                render: (row) => groupTypeToName[row.type],
                                visible: true
                            }
                        ]
                    } initialRowsById={new Map(Array.from(groups.values()).map(group => {
                        return [group.id, {
                            name: group.name,
                            id: group.id,
                            type: group.type
                        }];
                    }))} initialOrderBy="name"
                        initialOrder="asc"
                        selectionEnabled
                        filterEnabled
                        sortingEnabled
                        addDeleteEnabled={admin}
                        addUri={"/organizations/" + organizationId + "/groups/" + group.id + "/groups/create?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}
                        onDelete={onDelete}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}