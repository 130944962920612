import React from 'react';
import { useParams } from "react-router-dom";
import GroupDashboard from "./GroupDashboard";
import TabLayout from "./TabLayout";
import GroupGroups from "./GroupGroups";
import GroupDevices from "./GroupDevices";
import { groupBy } from "./common";
import GroupSettings from "./GroupSettings";

export default function GroupLayout({ ds, since, until, admin, kiosk }) {
    const params = useParams();

    const organizationId = params.organizationId;
    const groupId = params.groupId;

    const [group, setGroup] = React.useState(null);

    React.useEffect(() => {
        ds.retrieveGroup(organizationId, groupId,
            group => setGroup(group))
    }, [organizationId, groupId]);

    function updateGroup(group, callback) {
        ds.updateGroup(organizationId, groupId, group, callback);
    }

    const [groups, setGroups] = React.useState([]);
    const groupsById = new Map(groups.map(value => [value.id, value]));

    var name = "Instalacje";

    if (group) {
        switch (group.type) {
            case "HEATING_COST_ALLOCATION_BUILDING":
                name = "Lokale";
                break;
            case "HEATING_COST_ALLOCATION_FLAT":
                name = "Pomieszczenia";
                break;
            case "HEATING_COST_ALLOCATION_COMMON_AREA":
                name = "Pomieszczenia";
                break;
        }
    }

    React.useEffect(() => {
        ds.retrieveGroupGroups(groupId,
            groups => setGroups(groups.map(value => value)))
    }, [organizationId, groupId]);

    const [devices, setDevices] = React.useState([]);
    const devicesById = new Map(devices.map(value => [value.id, value]));

    React.useEffect(() => {
        ds.retrieveGroupDevices(groupId,
            devices => setDevices(devices.map(value => value)))
    }, [organizationId, groupId]);

    const [groupGroupDevicesAttributes, setGroupGroupDeviceAttributes] = React.useState([]);
    const groupDevicesByDeviceId = new Map(Object.entries(groupBy(groupGroupDevicesAttributes, "deviceId"))
        .map(([deviceId, values]) => [deviceId, values[0]]));

    React.useEffect(() => {
        ds.retrieveGroupGroupDeviceAttributes(groupId,
            groupDevices => setGroupGroupDeviceAttributes(groupDevices.map(value => value)))
    }, [organizationId, groupId]);

    const [deviceStatuses, setDeviceStatuses] = React.useState([]);

    function calculateDeviceStatusesByDeviceId() {
        return new Map(Object.entries(groupBy(deviceStatuses, "deviceId"))
            .map(([deviceId, values]) => [deviceId, values.sort((a, b) => b.time - a.time)]));
    }

    const deviceStatusesByDeviceId = calculateDeviceStatusesByDeviceId();

    React.useEffect(() => {
        const source = ds.receiveGroupDeviceStatuses(
            groupId,
            since,
            until);

        let opening = true;
        source.onMessages(incomingDeviceStatuses => {
            setDeviceStatuses(prevState => {
                let updated = opening ? [] : prevState.slice();
                opening = false;
                incomingDeviceStatuses.forEach(value => updated.push(value));
                return updated.sort((a, b) => b.time - a.time);
            });
        });

        return function cleanup() {
            source.close();
        };
    }, [organizationId, groupId, since, until]);

    function onDelete(selected) {
        selected.forEach(childGroupId =>
            ds.deleteGroupGroup(groupId, childGroupId, resp => {
                ds.retrieveGroupGroups(groupId,
                    childGroups => setGroups(childGroups.map(value => value)))
            })
        )
    }

    function onFileSelected() {

    }

    const content = kiosk ?
        (<GroupDashboard organizationId={organizationId} groupId={groupId} since={since} until={until} devicesById={devicesById} groupGroupDeviceAttributesByDeviceId={groupDevicesByDeviceId} deviceStatusesByDeviceId={deviceStatusesByDeviceId} deviceStatuses={deviceStatuses} kiosk={kiosk} />)
        : (
            <TabLayout tabs={
                [
                    { label: "Tablica", name: "dashboard", children: <GroupDashboard organizationId={organizationId} groupId={groupId} since={since} until={until} devicesById={devicesById} groupGroupDeviceAttributesByDeviceId={groupDevicesByDeviceId} deviceStatusesByDeviceId={deviceStatusesByDeviceId} deviceStatuses={deviceStatuses} kiosk={kiosk} /> },
                    group && group.type !== "HEATING_COST_ALLOCATION_ROOM" ? { label: name, name: "groups", children: <GroupGroups organizationId={organizationId} group={group} since={since} until={until} groups={groupsById} onDelete={onDelete} admin={admin} /> } : null,
                    { label: "Urządzenia", name: "devices", children: <GroupDevices organizationId={organizationId} groupId={groupId} since={since} until={until} devices={devicesById} deviceStatuses={deviceStatusesByDeviceId} onDelete={onDelete} admin={admin} /> },
                    //{label: "Odczyty", name: "readings", children: <GroupReadings organizationId={organizationId} groupId={groupId} since={since} until={until} devicesByDeviceId={devicesById} deviceStatuses={deviceStatuses}/>},
                    //{label: "Alarmy", name: "alarms", children: <GroupAlarms organizationId={organizationId} groupId={groupId} since={since} until={until} devicesByDeviceId={devicesById} deviceStatuses={deviceStatuses}/>},
                    { label: "Ustawienia", name: "settings", children: <GroupSettings group={group} onUpdate={updateGroup} admin={admin} /> }
                ].filter(entry => entry !== null)
            } />
        );

    return content;
}