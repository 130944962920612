import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import RichTable from "./RichTable";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    item: {
        height: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
        lineHeight: "1rem"
    },
}));

export default function OrganizationDevices({ organizationId, since, until, devicesById, onDelete, admin }) {
    const classes = useStyles();

    const deviceTypeToName = {
        "FILA_HEATING_COST_ALLOCATOR": "Podzielnik ciepła Fila",
        "DRAGINO_LHT65": "Dragino LHT65",
        "DRAGINO_LGT92": "Dragino LGT92",
        "DRAGINO_LDS01": "Dragino LDS01",
        "DRAGINO_LWL01": "Dragino LWL01",
        "RAK_7204": "RAK 7204",
        "OCUPOLY_PEOPLE_COUNTER": "Ocupoly People Counter",
        "OCUPOLY_PARKING_SENSOR": "Ocupoly Parking Sensor",
        "ADEUNIS_TEMP_4": "Adeunis Temp 4",
        "TALKPOOL_OY1110": "Talkpool OY1110",
        "TEKTELIC_SMART_ROOM_SENSOR": "Tektelic Smart Room Sensor"
    };

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <RichTable name="Urządzenia" columns={
                        [
                            {
                                id: 'name',
                                numeric: false,
                                disablePadding: false,
                                label: 'Nazwa',
                                render: (row) => (<Link className={classes.link}
                                    to={"/organizations/" + organizationId + "/devices/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>),
                                visible: true
                            },
                            { id: 'id', numeric: false, disablePadding: false, label: 'ID', visible: true },
                            {
                                id: 'type',
                                numeric: false,
                                disablePadding: false,
                                label: 'Typ',
                                render: (row) => deviceTypeToName[row.type],
                                visible: true
                            }
                        ]
                    } initialRowsById={new Map(Array.from(devicesById.values()).map(device => {
                        return [device.id, {
                            name: device.name,
                            id: device.id,
                            type: device.type
                        }];
                    }))} initialOrderBy="name"
                        initialOrder="asc"
                        selectionEnabled
                        filterEnabled
                        sortingEnabled
                        addDeleteEnabled={admin}
                        addUri={"/organizations/" + organizationId + "/devices/create?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}
                        onDelete={onDelete}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}