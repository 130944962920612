import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import App from './App';
import theme from './theme';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { authorize, getUrlParameter, logout, parseJwt, stripTrailingSlash } from "./common";

var logoutUser = function () {
    console.log("test");
};

fetch("/config.json")
    .then(config => config.json())
    .then(config => {
        const kiosk = getUrlParameter("kiosk");

        // if(window.location.host !== "localhost:3000") {
        //     config.baseUri = "https://" + window.location.host + "/api/v1";
        // }

        if (kiosk) {
            ReactDOM.render(
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <BrowserRouter>
                        <Switch>
                            <Route path="/organizations/:organizationId/groups/:groupId/dashboard">
                                <App config={config} kiosk={kiosk} />
                            </Route>
                        </Switch>
                    </BrowserRouter>
                </ThemeProvider>,
                document.querySelector('#root')
            )
        }
        else {
            localStorage.removeItem("access-token");
            const code = getUrlParameter("access_token");
            if (code !== "") {
                localStorage.setItem("access-token", code);
                window.history.replaceState && window.history.replaceState(
                    null, document.title, window.location.pathname + window.location.search.replace(/[?&]code=[^&]+/, '').replace(/^&/, '?') + window.location.hash
                );
                window.setTimeout(authorize, 1000 * 60 * 60 * 24);
            }

            if (localStorage.getItem("access-token") === null || localStorage.getItem("access-token") === "") {
                authorize();
            } else {
                const organizationId = parseJwt(localStorage.getItem("access-token")).organization.split(" ")[0];

                ReactDOM.render(
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <BrowserRouter>
                            <Switch>
                                <Route path="/organizations/:organizationId/groups/:groupId">
                                    <App config={config} />
                                </Route>
                                <Route path="/organizations/:organizationId/dashboard">
                                    <App config={config} />
                                </Route>
                                <Route path="/organizations/:organizationId/groups">
                                    <App config={config} />
                                </Route>
                                <Route path="/organizations/:organizationId/devices">
                                    <App config={config} />
                                </Route>
                                <Route path="/">
                                    <Redirect to={{ pathname: "/organizations/" + organizationId + "/groups" }} />
                                </Route>
                            </Switch>
                        </BrowserRouter>
                    </ThemeProvider>,
                    document.querySelector('#root')
                )
            }
        }
    }
    );