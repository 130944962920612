import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Form from "./Form";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as moment from "moment";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    subheader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    label: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        fontSize: "0.75rem"
    },
}));

export default function DeviceForm({ edit, initialDevice, onSubmit, admin }) {
    const classes = useStyles();

    const [device, setDevice] = React.useState(initialDevice);

    console.log(device);

    function onFormSubmit(event) {
        device.notificationMsisdns = device.notificationMsisdns.filter(str => str !== "");
        device.notificationEmailAddresses = device.notificationEmailAddresses.filter(str => str !== "");

        device.alarmTimeSinceLastSeenNotificationMsisdns = device.alarmTimeSinceLastSeenNotificationMsisdns == null ? null : device.alarmTimeSinceLastSeenNotificationMsisdns.filter(str => str !== "");
        device.alarmTimeSinceLastSeenNotificationEmailAddresses = device.alarmTimeSinceLastSeenNotificationEmailAddresses == null ? null : device.alarmTimeSinceLastSeenNotificationEmailAddresses.filter(str => str !== "");

        device.alarmTemperatureNotificationMsisdns = device.alarmTemperatureNotificationMsisdns == null ? null : device.alarmTemperatureNotificationMsisdns.filter(str => str !== "");
        device.alarmTemperatureNotificationEmailAddresses = device.alarmTemperatureNotificationEmailAddresses == null ? null : device.alarmTemperatureNotificationEmailAddresses.filter(str => str !== "");

        device.alarmInput1TemperatureNotificationMsisdns = device.alarmInput1TemperatureNotificationMsisdns == null ? null : device.alarmInput1TemperatureNotificationMsisdns.filter(str => str !== "");
        device.alarmInput1TemperatureNotificationEmailAddresses = device.alarmInput1TemperatureNotificationEmailAddresses == null ? null : device.alarmInput1TemperatureNotificationEmailAddresses.filter(str => str !== "");

        device.alarmInput2TemperatureNotificationMsisdns = device.alarmInput2TemperatureNotificationMsisdns == null ? null : device.alarmInput2TemperatureNotificationMsisdns.filter(str => str !== "");
        device.alarmInput2TemperatureNotificationEmailAddresses = device.alarmInput2TemperatureNotificationEmailAddresses == null ? null : device.alarmInput2TemperatureNotificationEmailAddresses.filter(str => str !== "");

        device.alarmHumidityNotificationMsisdns = device.alarmHumidityNotificationMsisdns == null ? null : device.alarmHumidityNotificationMsisdns.filter(str => str !== "");
        device.alarmHumidityNotificationEmailAddresses = device.alarmHumidityNotificationEmailAddresses == null ? null : device.alarmHumidityNotificationEmailAddresses.filter(str => str !== "");

        onSubmit(device);
        event.preventDefault();
    }

    function onChange(updateState) {
        setDevice(prevState => {
            const prevStateCloned = Object.assign({}, prevState);
            return updateState(prevStateCloned);
        });
    }

    return <Form title="Ustawienia" onSubmit={onFormSubmit} submitButtonLabel={edit ? "Zapisz" : "Dodaj"} admin={admin}>
        <FormControl className={classes.formControl}>
            <FormLabel className={classes.label}>Typ</FormLabel>
            <Select
                labelId="type"
                value={device.type}
                onChange={event => {
                    const value = event.target.value;
                    onChange(device => {
                        device.type = value;
                        return device;
                    });
                }}
                disabled={!admin}
            >
                <MenuItem value="FILA_HEATING_COST_ALLOCATOR">Podzielnik ciepła Fila</MenuItem>
                <MenuItem value="DRAGINO_LHT65">Dragino LHT65</MenuItem>
                <MenuItem value="DRAGINO_LGT92">Dragino LGT92</MenuItem>
                <MenuItem value="DRAGINO_LDS01">Dragino LDS01</MenuItem>
                <MenuItem value="DRAGINO_LWL01">Dragino LWL01</MenuItem>
                <MenuItem value="RAK_7204">RAK 7204</MenuItem>
                <MenuItem value="OCUPOLY_PEOPLE_COUNTER">Ocupoly People Counter</MenuItem>
                <MenuItem value="OCUPOLY_PARKING_SENSOR">Ocupoly Parking Sensor</MenuItem>
                <MenuItem value="ADEUNIS_TEMP_4">Adeunis Temp 4</MenuItem>
                <MenuItem value="TALKPOOL_OY1110">Talkpool OY1110</MenuItem>
                <MenuItem value="TEKTELIC_SMART_ROOM_SENSOR">Tektelic Smart Room Sensor</MenuItem>
            </Select>
        </FormControl>
        <TextField
            name="name"
            label="Nazwa"
            margin="normal"
            value={device.name}
            onChange={event => {
                const value = event.target.value;
                onChange(device => {
                    device.name = value;
                    return device;
                });
            }}
            inputProps={{
                pattern: "[a-zA-Z0-9 -_]+",
            }}
            fullWidth
            required
            helperText={"Dozwolone są tylko małe znaki alfanumeryczne, spacja, \"-\", \"-\" lub \".\""}
            disabled={!admin}
        />
        <TextField
            name="description"
            label="Opis"
            margin="normal"
            value={device.description ? device.description : ""}
            onChange={event => {
                const value = event.target.value !== "" ? event.target.value : null;
                onChange(device => {
                    device.description = value;
                    return device;
                });
            }}
            inputProps={{
                pattern: "[^\\n]+",
            }}
            fullWidth
            disabled={!admin}
        />
        <Typography variant="button" display="block" className={classes.header}>
            Powiadomienia
        </Typography>
        <TextField
            name="notificationMsisdns"
            label="Numery telefonów"
            margin="normal"
            value={device.notificationMsisdns.join(",")}
            onChange={event => {
                const value = event.target.value.split(",").map(str => str.trim());
                onChange(device => {
                    device.notificationMsisdns = value;
                    return device;
                });
            }}
            inputProps={{
                pattern: "^(\\+(?:[0-9] ?){6,14}[0-9])(, ?\\+(?:[0-9] ?){6,14}[0-9])*$",
            }}
            fullWidth
            helperText={"Numery telefonów w formacie MSISDN (z prefiksem \"+\" i kodem kraju), przedzielone przecinkami"}
            disabled={!admin}
        />
        <TextField
            name="notificationEmailAddresses"
            label="Adresy e-mail"
            margin="normal"
            value={device.notificationEmailAddresses.join(",")}
            onChange={event => {
                const value = event.target.value.split(",").map(str => str.trim());
                onChange(device => {
                    device.notificationEmailAddresses = value;
                    return device;
                });
            }}
            inputProps={{
                pattern: "^((([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))(, ?(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))*$",
            }}
            fullWidth
            helperText={"Adres e-mail w formacie zgodnym z RFC 5322, przedzielone przecinkami"}
            disabled={!admin}
        />
        <Typography variant="button" display="block" className={classes.header}>
            Instalacja
        </Typography>
        <TextField
            name="installationDistance"
            label="Wysokość"
            type="number"
            margin="normal"
            InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
            value={device.installationDistance ? device.installationDistance : ""}
            onChange={event => {
                const value = event.target.value !== "" ? event.target.value : null;
                onChange(device => {
                    device.installationDistance = value;
                    return device;
                });
            }}
            fullWidth
            disabled={!admin}
        />
        <Typography variant="button" display="block" className={classes.header}>
            Alarmowanie
        </Typography>
        <Typography variant="subtitle2" display="block" className={classes.subheader}>
            Czas od ostatniego kontaktu
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="alarmTimeSinceLastSeenLowThreshold"
                    label="Dolny próg"
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">min</InputAdornment>,
                    }}
                    margin="normal"
                    value={device.alarmTimeSinceLastSeenLowThreshold != null ? device.alarmTimeSinceLastSeenLowThreshold.asMinutes() : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? moment.duration("PT" + parseInt(event.target.value) + "M") : null;
                        onChange(device => {
                            device.alarmTimeSinceLastSeenLowThreshold = value;
                            return device;
                        });
                    }}
                    fullWidth
                    helperText={"Dolny próg w minutach"}
                    disabled={!admin}
                />
                <TextField
                    name="alarmTimeSinceLastSeenHighThreshold"
                    label="Górny próg"
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">min</InputAdornment>,
                    }}
                    margin="normal"
                    value={device.alarmTimeSinceLastSeenHighThreshold != null ? device.alarmTimeSinceLastSeenHighThreshold.asMinutes() : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? moment.duration("PT" + parseInt(event.target.value) + "M") : null;
                        onChange(device => {
                            device.alarmTimeSinceLastSeenHighThreshold = value;
                            return device;
                        });
                    }}
                    fullWidth
                    helperText={"Górny próg w minutach"}
                    disabled={!admin}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="alarmTimeSinceLastSeenNotificationMsisdns"
                    label="Numery telefonów"
                    margin="normal"
                    value={device.alarmTimeSinceLastSeenNotificationMsisdns ? device.alarmTimeSinceLastSeenNotificationMsisdns.join(",") : ""}
                    onChange={event => {
                        const value = event.target.value.split(",").map(str => str.trim());
                        onChange(device => {
                            device.alarmTimeSinceLastSeenNotificationMsisdns = value.length > 0 ? value : null;
                            return device;
                        });
                    }}
                    inputProps={{
                        pattern: "^(\\+(?:[0-9] ?){6,14}[0-9])(, ?\\+(?:[0-9] ?){6,14}[0-9])*$",
                    }}
                    fullWidth
                    helperText={"Numery telefonów w formacie MSISDN (z prefiksem \"+\" i kodem kraju), przedzielone przecinkami"}
                    disabled={!admin}
                />
                <TextField
                    name="alarmTimeSinceLastSeenNotificationEmailAddresses"
                    label="Adresy e-mail"
                    margin="normal"
                    value={device.alarmTimeSinceLastSeenNotificationEmailAddresses ? device.alarmTimeSinceLastSeenNotificationEmailAddresses.join(",") : ""}
                    onChange={event => {
                        const value = event.target.value.split(",").map(str => str.trim());
                        onChange(device => {
                            device.alarmTimeSinceLastSeenNotificationEmailAddresses = value.length > 0 ? value : null;
                            return device;
                        });
                    }}
                    inputProps={{
                        pattern: "^((([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))(, ?(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))*$",
                    }}
                    fullWidth
                    helperText={"Adres e-mail w formacie zgodnym z RFC 5322, przedzielone przecinkami"}
                    disabled={!admin}
                />
            </Grid>
        </Grid>
        <Typography variant="subtitle2" display="block" className={classes.subheader}>
            Temperatura
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="alarmTemperatureLowThreshold"
                    label="Dolny próg"
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                    }}
                    margin="normal"
                    value={device.alarmTemperatureLowThreshold != null ? device.alarmTemperatureLowThreshold : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? event.target.value : null;
                        onChange(device => {
                            device.alarmTemperatureLowThreshold = value;
                            return device;
                        });
                    }}
                    fullWidth
                    helperText={"Dolny próg w stopniach Celciusa"}
                    disabled={!admin}
                />
                <TextField
                    name="alarmTemperatureHighThreshold"
                    label="Górny próg"
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                    }}
                    margin="normal"
                    value={device.alarmTemperatureHighThreshold != null ? device.alarmTemperatureHighThreshold : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? event.target.value : null;
                        onChange(device => {
                            device.alarmTemperatureHighThreshold = value;
                            return device;
                        });
                    }}
                    fullWidth
                    helperText={"Górny próg w stopniach Celciusa"}
                    disabled={!admin}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="alarmTemperatureNotificationMsisdns"
                    label="Numery telefonów"
                    margin="normal"
                    value={device.alarmTemperatureNotificationMsisdns ? device.alarmTemperatureNotificationMsisdns.join(",") : ""}
                    onChange={event => {
                        const value = event.target.value.split(",").map(str => str.trim());
                        onChange(device => {
                            device.alarmTemperatureNotificationMsisdns = value.length > 0 ? value : null;
                            return device;
                        });
                    }}
                    inputProps={{
                        pattern: "^(\\+(?:[0-9] ?){6,14}[0-9])(, ?\\+(?:[0-9] ?){6,14}[0-9])*$",
                    }}
                    fullWidth
                    helperText={"Numery telefonów w formacie MSISDN (z prefiksem \"+\" i kodem kraju), przedzielone przecinkami"}
                    disabled={!admin}
                />
                <TextField
                    name="alarmTemperatureNotificationEmailAddresses"
                    label="Adresy e-mail"
                    margin="normal"
                    value={device.alarmTemperatureNotificationEmailAddresses ? device.alarmTemperatureNotificationEmailAddresses.join(",") : ""}
                    onChange={event => {
                        const value = event.target.value.split(",").map(str => str.trim());
                        onChange(device => {
                            device.alarmTemperatureNotificationEmailAddresses = value.length > 0 ? value : null;
                            return device;
                        });
                    }}
                    inputProps={{
                        pattern: "^((([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))(, ?(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))*$",
                    }}
                    fullWidth
                    helperText={"Adres e-mail w formacie zgodnym z RFC 5322, przedzielone przecinkami"}
                    disabled={!admin}
                />
            </Grid>
        </Grid>
        <Typography variant="subtitle2" display="block" className={classes.subheader}>
            Temperatura: wejście 1
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="alarmInput1TemperatureLowThreshold"
                    label="Dolny próg"
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                    }}
                    margin="normal"
                    value={device.alarmInput1TemperatureLowThreshold != null ? device.alarmInput1TemperatureLowThreshold : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? event.target.value : null;
                        onChange(device => {
                            device.alarmInput1TemperatureLowThreshold = value;
                            return device;
                        });
                    }}
                    fullWidth
                    helperText={"Dolny próg w stopniach Celciusa"}
                    disabled={!admin}
                />
                <TextField
                    name="alarmInput1TemperatureHighThreshold"
                    label="Górny próg"
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                    }}
                    margin="normal"
                    value={device.alarmInput1TemperatureHighThreshold != null ? device.alarmInput1TemperatureHighThreshold : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? event.target.value : null;
                        onChange(device => {
                            device.alarmInput1TemperatureHighThreshold = value;
                            return device;
                        });
                    }}
                    fullWidth
                    helperText={"Górny próg w stopniach Celciusa"}
                    disabled={!admin}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="alarmInput1TemperatureNotificationMsisdns"
                    label="Numery telefonów"
                    margin="normal"
                    value={device.alarmInput1TemperatureNotificationMsisdns ? device.alarmInput1TemperatureNotificationMsisdns.join(",") : ""}
                    onChange={event => {
                        const value = event.target.value.split(",").map(str => str.trim());
                        onChange(device => {
                            device.alarmInput1TemperatureNotificationMsisdns = value.length > 0 ? value : null;
                            return device;
                        });
                    }}
                    inputProps={{
                        pattern: "^(\\+(?:[0-9] ?){6,14}[0-9])(, ?\\+(?:[0-9] ?){6,14}[0-9])*$",
                    }}
                    fullWidth
                    helperText={"Numery telefonów w formacie MSISDN (z prefiksem \"+\" i kodem kraju), przedzielone przecinkami"}
                    disabled={!admin}
                />
                <TextField
                    name="alarmInput1TemperatureNotificationEmailAddresses"
                    label="Adresy e-mail"
                    margin="normal"
                    value={device.alarmInput1TemperatureNotificationEmailAddresses ? device.alarmInput1TemperatureNotificationEmailAddresses.join(",") : ""}
                    onChange={event => {
                        const value = event.target.value.split(",").map(str => str.trim());
                        onChange(device => {
                            device.alarmInput1TemperatureNotificationEmailAddresses = value.length > 0 ? value : null;
                            return device;
                        });
                    }}
                    inputProps={{
                        pattern: "^((([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))(, ?(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))*$",
                    }}
                    fullWidth
                    helperText={"Adres e-mail w formacie zgodnym z RFC 5322, przedzielone przecinkami"}
                    disabled={!admin}
                />
            </Grid>
        </Grid>
        <Typography variant="subtitle2" display="block" className={classes.subheader}>
            Temperatura: wejście 2
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="alarmInput2TemperatureLowThreshold"
                    label="Dolny próg"
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                    }}
                    margin="normal"
                    value={device.alarmInput2TemperatureLowThreshold != null ? device.alarmInput2TemperatureLowThreshold : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? event.target.value : null;
                        onChange(device => {
                            device.alarmInput2TemperatureLowThreshold = value;
                            return device;
                        });
                    }}
                    fullWidth
                    helperText={"Dolny próg w stopniach Celciusa"}
                    disabled={!admin}
                />
                <TextField
                    name="alarmInput2TemperatureHighThreshold"
                    label="Górny próg"
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                    }}
                    margin="normal"
                    value={device.alarmInput2TemperatureHighThreshold != null ? device.alarmInput2TemperatureHighThreshold : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? event.target.value : null;
                        onChange(device => {
                            device.alarmInput2TemperatureHighThreshold = value;
                            return device;
                        });
                    }}
                    fullWidth
                    helperText={"Górny próg w stopniach Celciusa"}
                    disabled={!admin}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="alarmInput2TemperatureNotificationMsisdns"
                    label="Numery telefonów"
                    margin="normal"
                    value={device.alarmInput2TemperatureNotificationMsisdns ? device.alarmInput2TemperatureNotificationMsisdns.join(",") : ""}
                    onChange={event => {
                        const value = event.target.value.split(",").map(str => str.trim());
                        onChange(device => {
                            device.alarmInput2TemperatureNotificationMsisdns = value.length > 0 ? value : null;
                            return device;
                        });
                    }}
                    inputProps={{
                        pattern: "^(\\+(?:[0-9] ?){6,14}[0-9])(, ?\\+(?:[0-9] ?){6,14}[0-9])*$",
                    }}
                    fullWidth
                    helperText={"Numery telefonów w formacie MSISDN (z prefiksem \"+\" i kodem kraju), przedzielone przecinkami"}
                    disabled={!admin}
                />
                <TextField
                    name="alarmInput2TemperatureNotificationEmailAddresses"
                    label="Adresy e-mail"
                    margin="normal"
                    value={device.alarmInput2TemperatureNotificationEmailAddresses ? device.alarmInput2TemperatureNotificationEmailAddresses.join(",") : ""}
                    onChange={event => {
                        const value = event.target.value.split(",").map(str => str.trim());
                        onChange(device => {
                            device.alarmInput2TemperatureNotificationEmailAddresses = value.length > 0 ? value : null;
                            return device;
                        });
                    }}
                    inputProps={{
                        pattern: "^((([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))(, ?(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))*$",
                    }}
                    fullWidth
                    helperText={"Adres e-mail w formacie zgodnym z RFC 5322, przedzielone przecinkami"}
                    disabled={!admin}
                />
            </Grid>
        </Grid>
        <Typography variant="subtitle2" display="block" className={classes.subheader}>
            Wilgotność
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="alarmHumidityLowThreshold"
                    label="Dolny próg"
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    margin="normal"
                    value={device.alarmHumidityLowThreshold != null ? device.alarmHumidityLowThreshold : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? event.target.value : null;
                        onChange(device => {
                            device.alarmHumidityLowThreshold = value;
                            return device;
                        });
                    }}
                    fullWidth
                    helperText={"Dolny próg w procentach"}
                    disabled={!admin}
                />
                <TextField
                    name="alarmHumidityHighThreshold"
                    label="Górny próg"
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    margin="normal"
                    value={device.alarmHumidityHighThreshold != null ? device.alarmHumidityHighThreshold : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? event.target.value : null;
                        onChange(device => {
                            device.alarmHumidityHighThreshold = value;
                            return device;
                        });
                    }}
                    fullWidth
                    helperText={"Górny próg w procentach"}
                    disabled={!admin}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="alarmHumidityNotificationMsisdns"
                    label="Numery telefonów"
                    margin="normal"
                    value={device.alarmHumidityNotificationMsisdns ? device.alarmHumidityNotificationMsisdns.join(",") : ""}
                    onChange={event => {
                        const value = event.target.value.split(",").map(str => str.trim());
                        onChange(device => {
                            device.alarmHumidityNotificationMsisdns = value.length > 0 ? value : null;
                            return device;
                        });
                    }}
                    inputProps={{
                        pattern: "^(\\+(?:[0-9] ?){6,14}[0-9])(, ?\\+(?:[0-9] ?){6,14}[0-9])*$",
                    }}
                    fullWidth
                    helperText={"Numery telefonów w formacie MSISDN (z prefiksem \"+\" i kodem kraju), przedzielone przecinkami"}
                    disabled={!admin}
                />
                <TextField
                    name="alarmHumidityNotificationEmailAddresses"
                    label="Adresy e-mail"
                    margin="normal"
                    value={device.alarmHumidityNotificationEmailAddresses ? device.alarmHumidityNotificationEmailAddresses.join(",") : ""}
                    onChange={event => {
                        const value = event.target.value.split(",").map(str => str.trim());
                        onChange(device => {
                            device.alarmHumidityNotificationEmailAddresses = value.length > 0 ? value : null;
                            return device;
                        });
                    }}
                    inputProps={{
                        pattern: "^((([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))(, ?(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))*$",
                    }}
                    fullWidth
                    helperText={"Adres e-mail w formacie zgodnym z RFC 5322, przedzielone przecinkami"}
                    disabled={!admin}
                />
            </Grid>
        </Grid>
        <Typography variant="button" display="block" className={classes.header}>
            LoRaWAN
        </Typography>
        <TextField
            name="lorawanDevEui"
            label="EUI"
            margin="normal"
            value={device.lorawanDevEui}
            onChange={event => {
                const value = event.target.value;
                onChange(device => {
                    device.id = "eui64-" + value;
                    device.lorawanDevEui = value;
                    if (!device.name) {
                        device.name = device.lorawanDevEui
                    }
                    return device;
                });
            }}
            inputProps={{
                pattern: "[A-Fa-f0-9]{16}"
            }}
            fullWidth
            required
            disabled={edit || !admin}
        />
        <TextField
            name="lorawanAppKey"
            label="Klucz aplikacyjny"
            margin="normal"
            type="password"
            value={device.lorawanAppKey}
            onChange={event => {
                const value = event.target.value;
                onChange(device => {
                    device.lorawanAppKey = value;
                    device.lorawanNwkKey = device.lorawanAppKey;
                    return device;
                });
            }}
            inputProps={{
                pattern: "[A-Fa-f0-9]{32}"
            }}
            fullWidth
            required
            disabled={edit || !admin}
        />
    </Form>;
}
