import TimeRange from "./TimeRange";

export default function Group(
    id,
    name,
    type,
    notificationTimeRange,
    notificationDaysOfWeek,
    floorArea,
    wallArea,
    wallHeatTransferCoefficient,
    windowSurface,
    windowHeatTransferCoefficient,
    deliveredHeat,
    heatingCost,
    heatingCostCurrency
) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.notificationTimeRange = notificationTimeRange;
    this.notificationDaysOfWeek = notificationDaysOfWeek;
    this.floorArea = floorArea;
    this.wallArea = wallArea;
    this.wallHeatTransferCoefficient = wallHeatTransferCoefficient;
    this.windowSurface = windowSurface;
    this.windowHeatTransferCoefficient = windowHeatTransferCoefficient;
    this.deliveredHeat = deliveredHeat;
    this.heatingCost = heatingCost;
    this.heatingCostCurrency = heatingCostCurrency;

    this.toJson = function () {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            notificationTimeRange: "{\"from\":\"07:00:00\",\"to\":\"17:00:00\"}",
            notificationDaysOfWeek: "[\"MONDAY\",\"TUESDAY\",\"WEDNESDAY\",\"THURSDAY\",\"FRIDAY\"]",
            floorArea: this.floorArea,
            wallArea: this.wallArea,
            wallHeatTransferCoefficient: this.wallHeatTransferCoefficient,
            windowSurface: this.windowSurface,
            windowHeatTransferCoefficient: this.windowHeatTransferCoefficient,
            deliveredHeat: this.deliveredHeat,
            heatingCost: this.heatingCost,
            heatingCostCurrency: this.heatingCostCurrency,
        };
    }
}

Group.fromJson = function (json) {
    return new Group(
        json.id,
        json.name,
        json.type,
        TimeRange.fromJson(json.notificationTimeRange),
        json.notificationDaysOfWeek,
        json.floorArea,
        json.wallArea,
        json.wallHeatTransferCoefficient,
        json.windowSurface,
        json.windowHeatTransferCoefficient,
        json.deliveredHeat,
        json.heatingCost,
        json.heatingCostCurrency);
};