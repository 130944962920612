import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Form from "./Form";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as moment from "moment";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";

// import MomentUtils from '@date-io/moment';
// import {
//     MuiPickersUtilsProvider,
//     TimePicker
// } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    subheader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    label: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        fontSize: "0.75rem"
    },
}));

export default function GroupForm({ edit, initialGroup, onSubmit, admin }) {
    const classes = useStyles();
    const [group, setGroup] = React.useState(initialGroup);

    function onFormSubmit(event) {
        onSubmit(group);
        event.preventDefault();
    }

    function onChange(updateState) {
        setGroup(prevState => {
            const prevStateCloned = Object.assign({}, prevState);
            return updateState(prevStateCloned);
        });
    }

    return <Form title="Ustawienia" onSubmit={onFormSubmit} submitButtonLabel={edit ? "Zapisz" : "Dodaj"} admin={admin}>
        <FormControl className={classes.formControl}>
            <FormLabel className={classes.label}>Typ</FormLabel>
            <Select
                labelId="type"
                value={group.type}
                onChange={event => {
                    const value = event.target.value;
                    onChange(group => {
                        group.type = value;
                        return group;
                    });
                }}
            //disabled={!admin}
            >
                <MenuItem value="HEATING_COST_ALLOCATION_BUILDING">Podział ciepła - budynek</MenuItem>
                <MenuItem value="HEATING_COST_ALLOCATION_FLAT">Podział ciepła - mieszkanie</MenuItem>
                <MenuItem value="HEATING_COST_ALLOCATION_COMMON_AREA">Podział ciepła - część wspólna</MenuItem>
                <MenuItem value="HEATING_COST_ALLOCATION_ROOM">Podział ciepła - pomieszczenie</MenuItem>
            </Select>
        </FormControl>
        <TextField
            name="name"
            label="Nazwa"
            margin="normal"
            value={group.name}
            onChange={event => {
                const value = event.target.value;
                onChange(group => {
                    group.name = value;
                    return group;
                });
            }}
            inputProps={{
                pattern: "[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 -_]+",
            }}
            fullWidth
            required
        //disabled={!admin}
        />
        {group.type === "HEATING_COST_ALLOCATION_BUILDING" && <React.Fragment>
            <TextField
                name="deliveredHeat"
                label="Ciepło dostarczone"
                type="number"
                margin="normal"
                inputProps={{
                    step: "0.001"
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">GJ</InputAdornment>,
                }}
                value={group.deliveredHeat ? group.deliveredHeat / 1000 : 0}
                onChange={event => {
                    const value = Math.trunc(event.target.value * 1000);
                    onChange(group => {
                        group.deliveredHeat = value;
                        return group;
                    });
                }}
                helperText=""
                fullWidth
            //disabled={!admin}
            />
            <TextField
                name="heatingCost"
                label="Cena ciepła"
                type="number"
                margin="normal"
                inputProps={{
                    step: "0.001"
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">Jednostka walutowa/GJ</InputAdornment>,
                }}
                value={group.heatingCost ? group.heatingCost : 0}
                onChange={event => {
                    const value = Math.trunc(event.target.value * 1000);
                    onChange(group => {
                        group.heatingCost = value;
                        return group;
                    });
                }}
                helperText=""
                fullWidth
            //disabled={!admin}
            />
            <FormControl className={classes.formControl}>
                <FormLabel className={classes.label}>Waluta ceny ciepła</FormLabel>
                <Select
                    labelId="heatingCostCurrency"
                    value={group.heatingCostCurrency}
                    onChange={event => {
                        const value = event.target.value;
                        onChange(group => {
                            group.heatingCostCurrency = value;
                            return group;
                        });
                    }}
                //disabled={!admin}
                >
                    <MenuItem value="PLN">PLN</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                </Select>
            </FormControl>
        </React.Fragment>}
        {(group.type === "HEATING_COST_ALLOCATION_FLAT" || group.type === "HEATING_COST_ALLOCATION_COMMON_AREA") && <React.Fragment>
            <TextField
                name="floorArea"
                label="Pole powierzchni podłogi"
                type="number"
                margin="normal"
                inputProps={{
                    step: "0.001"
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">m²</InputAdornment>,
                }}
                value={group.floorArea ? group.floorArea : 0}
                onChange={event => {
                    const value = event.target.value;
                    onChange(group => {
                        group.floorArea = value;
                        return group;
                    });
                }}
                helperText=""
                fullWidth
            //disabled={!admin}
            />
        </React.Fragment>}
        {group.type === "HEATING_COST_ALLOCATION_ROOM" && <React.Fragment>
            <TextField
                name="wallArea"
                label="Pole powierzchni ścian"
                type="number"
                margin="normal"
                inputProps={{
                    step: "0.001"
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">m²</InputAdornment>,
                }}
                value={group.wallArea ? group.wallArea : 0}
                onChange={event => {
                    const value = event.target.value;
                    onChange(group => {
                        group.wallArea = value;
                        return group;
                    });
                }}
                helperText=""
                fullWidth
            //disabled={!admin}
            />
            <TextField
                name="wallHeatTransferCoefficient"
                label="Współczynnik przenikalności ścian"
                type="number"
                margin="normal"
                inputProps={{
                    step: "0.001"
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">W/(m²K)</InputAdornment>,
                }}
                value={group.wallHeatTransferCoefficient ? group.wallHeatTransferCoefficient : 0}
                onChange={event => {
                    const value = event.target.value;
                    onChange(group => {
                        group.wallHeatTransferCoefficient = value;
                        return group;
                    });
                }}
                fullWidth
            //disabled={!admin}
            />
            <TextField
                name="windowArea"
                label="Pole powierzchni okien"
                type="number"
                margin="normal"
                inputProps={{
                    step: "0.001"
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">m²</InputAdornment>,
                }}
                value={group.windowArea ? group.windowArea : 0}
                onChange={event => {
                    const value = event.target.value;
                    onChange(group => {
                        group.windowArea = value;
                        return group;
                    });
                }}
                fullWidth
            //disabled={!admin}
            />
            <TextField
                name="windowHeatTransferCoefficient"
                label="Współczynnik przenikalności okien"
                type="number"
                margin="normal"
                inputProps={{
                    step: "0.001"
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">W/(m²K)</InputAdornment>,
                }}
                value={group.windowHeatTransferCoefficient ? group.windowHeatTransferCoefficient : 0}
                onChange={event => {
                    const value = event.target.value;
                    onChange(group => {
                        group.windowHeatTransferCoefficient = value;
                        return group;
                    });
                }}
                fullWidth
            //disabled={!admin}
            />
        </React.Fragment>}
        {/*<Typography variant="button" display="block" className={classes.header}>*/}
        {/*    Powiadomienia*/}
        {/*</Typography>*/}
        {/*<MuiPickersUtilsProvider utils={MomentUtils}>*/}
        {/*    <Grid container spacing={3}>*/}
        {/*        <Grid item xs={12} md={6} lg={6}>*/}
        {/*            <TimePicker*/}
        {/*                name="notificationTimeRange.from"*/}
        {/*                value={new Date("1970-01-01T" + group.notificationTimeRange.from)}*/}
        {/*                onChange={value => {*/}
        {/*                    setGroup(prevState => {*/}
        {/*                        const updateState = Object.assign({}, prevState);*/}
        {/*                        updateState.notificationTimeRange.from = value.format("HH:mm:ss");*/}
        {/*                        return updateState;*/}
        {/*                    });*/}
        {/*                }}*/}
        {/*                views={["hours"]}*/}
        {/*                label="Od"*/}
        {/*                margin="normal"*/}
        {/*                ampm={false}*/}
        {/*                invalidDateMessage={"Nieprawidłowy format"}*/}
        {/*                cancelLabel="Anuluj"*/}
        {/*                okLabel="Ustaw"*/}
        {/*                format="HH:mm:ss"*/}
        {/*                disabled={!admin}*/}
        {/*            />*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={12} md={6} lg={6}>*/}
        {/*            <TimePicker*/}
        {/*                name="notificationTimeRange.to"*/}
        {/*                value={new Date("1970-01-01T" + group.notificationTimeRange.to)}*/}
        {/*                onChange={value => {*/}
        {/*                    setGroup(prevState => {*/}
        {/*                        const updateState = Object.assign({}, prevState);*/}
        {/*                        updateState.notificationTimeRange.to = value.format("HH:mm:ss");*/}
        {/*                        return updateState;*/}
        {/*                    });*/}
        {/*                }}*/}
        {/*                views={["hours"]}*/}
        {/*                label="Do"*/}
        {/*                margin="normal"*/}
        {/*                ampm={false}*/}
        {/*                invalidDateMessage={"Nieprawidłowy format"}*/}
        {/*                cancelLabel="Anuluj"*/}
        {/*                okLabel="Ustaw"*/}
        {/*                format="HH:mm:ss"*/}
        {/*                disabled={!admin}*/}
        {/*            />*/}
        {/*        </Grid>*/}
        {/*    </Grid>*/}
        {/*</MuiPickersUtilsProvider>*/}
    </Form>;
}